define("discourse/plugins/discourse-subscriptions/discourse/models/user-payment", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserPayment extends _object.default {
    static findAll() {
      return (0, _ajax.ajax)("/s/user/payments", {
        method: "get"
      }).then(result => result.map(payment => {
        return UserPayment.create(payment);
      }));
    }
    amountDollars(amount) {
      return parseFloat(amount / 100).toFixed(2);
    }
    static #_ = (() => dt7948.n(this.prototype, "amountDollars", [(0, _decorators.default)("amount")]))();
  }
  _exports.default = UserPayment;
});