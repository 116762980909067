define("discourse/plugins/discourse-subscriptions/discourse/controllers/subscriptions", ["exports", "@ember/controller", "@ember/object", "@ember/template", "I18n"], function (_exports, _controller, _object, _template, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubscriptionsController extends _controller.default {
    init() {
      super.init(...arguments);
      if (this.currentUser) {
        this.currentUser.checkEmail().then(() => this.set("email", this.currentUser.email));
      }
    }
    get pricingTable() {
      try {
        const pricingTableId = this.siteSettings.discourse_subscriptions_pricing_table_id;
        const publishableKey = this.siteSettings.discourse_subscriptions_public_key;
        const pricingTableEnabled = this.siteSettings.discourse_subscriptions_pricing_table_enabled;
        if (!pricingTableEnabled || !pricingTableId || !publishableKey) {
          throw new Error("Pricing table not configured");
        }
        if (this.currentUser) {
          return (0, _template.htmlSafe)(`<stripe-pricing-table
                pricing-table-id="${pricingTableId}"
                publishable-key="${publishableKey}"
                customer-email="${this.email}"></stripe-pricing-table>`);
        } else {
          return (0, _template.htmlSafe)(`<stripe-pricing-table
                pricing-table-id="${pricingTableId}"
                publishable-key="${publishableKey}"
                ></stripe-pricing-table>`);
        }
      } catch {
        return _I18n.default.t("discourse_subscriptions.subscribe.no_products");
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "pricingTable", [(0, _object.computed)("email")]))();
  }
  _exports.default = SubscriptionsController;
});