define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions", ["exports", "@ember/object", "@ember/routing/route", "@ember/service"], function (_exports, _object, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    showSettings() {
      const controller = this.controllerFor("adminSiteSettings");
      this.router.transitionTo("adminSiteSettingsCategory", "plugins").then(() => {
        controller.set("filter", "plugin:discourse-subscriptions campaign");
        controller.set("_skipBounce", true);
        controller.filterContentNow("plugins");
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showSettings", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsRoute;
});