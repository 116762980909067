define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-products-show-plans-show", ["exports", "@ember/routing/route", "rsvp", "discourse/models/group", "discourse/plugins/discourse-subscriptions/discourse/models/admin-plan"], function (_exports, _route, _rsvp, _group, _adminPlan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsProductsShowPlansShowRoute extends _route.default {
    model(params) {
      const id = params["plan-id"];
      const product = this.modelFor("adminPlugins.discourse-subscriptions.products.show").product;
      let plan;
      if (id === "new") {
        plan = _adminPlan.default.create({
          active: true,
          isNew: true,
          interval: "month",
          type: "recurring",
          isRecurring: true,
          currency: this.siteSettings.discourse_subscriptions_currency,
          product: product.get("id"),
          metadata: {
            group_name: null
          }
        });
      } else {
        plan = _adminPlan.default.find(id).then(result => {
          result.isRecurring = result.type === "recurring";
          return result;
        });
      }
      const groups = _group.default.findAll({
        ignore_automatic: true
      });
      return (0, _rsvp.hash)({
        plan,
        product,
        groups
      });
    }
    renderTemplate() {
      this.render("adminPlugins.discourse-subscriptions.products.show.plans.show", {
        into: "adminPlugins.discourse-subscriptions.products",
        outlet: "main",
        controller: "adminPlugins.discourse-subscriptions.products.show.plans.show"
      });
    }
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsProductsShowPlansShowRoute;
});