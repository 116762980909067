define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-plans-index", ["exports", "@ember/controller", "@ember/object", "discourse/lib/url"], function (_exports, _controller, _object, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsPlansIndexController extends _controller.default {
    editPlan(id) {
      return _url.default.redirectTo(`/admin/plugins/discourse-subscriptions/plans/${id}`);
    }
    static #_ = (() => dt7948.n(this.prototype, "editPlan", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsPlansIndexController;
});