define("discourse/plugins/discourse-subscriptions/discourse/routes/subscribe-show", ["exports", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/plan", "discourse/plugins/discourse-subscriptions/discourse/models/product", "discourse/plugins/discourse-subscriptions/discourse/models/subscription"], function (_exports, _route, _plan, _product, _subscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubscribeShowRoute extends _route.default {
    model(params) {
      const product_id = params["subscription-id"];
      return _subscription.default.show(product_id).then(result => {
        result.product = _product.default.create(result.product);
        result.plans = result.plans.map(plan => {
          return _plan.default.create(plan);
        });
        return result;
      });
    }
  }
  _exports.default = SubscribeShowRoute;
});