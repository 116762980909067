define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-subscriptions", ["exports", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription"], function (_exports, _route, _adminSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsSubscriptionsRoute extends _route.default {
    model() {
      return _adminSubscription.default.find();
    }
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsSubscriptionsRoute;
});