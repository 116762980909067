define("discourse/plugins/discourse-subscriptions/discourse/routes/user-billing", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserBillingRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    templateName = "user/billing";
    setupController(controller, model) {
      if (this.currentUser.id !== this.modelFor("user").id) {
        this.router.replaceWith("userActivity");
      } else {
        controller.setProperties({
          model
        });
      }
    }
  }
  _exports.default = UserBillingRoute;
});