define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-plans-show", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _service, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsPlansShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    createPlan() {
      if (this.get("model.plan.product_id") === undefined) {
        const productID = this.get("model.products.firstObject.id");
        this.set("model.plan.product_id", productID);
      }
      this.get("model.plan").save().then(() => {
        this.router.transitionTo("adminPlugins.discourse-subscriptions.plans");
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_2 = (() => dt7948.n(this.prototype, "createPlan", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsPlansShowController;
});