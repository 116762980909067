define("discourse/plugins/discourse-subscriptions/discourse/routes/user-billing-payments", ["exports", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/user-payment"], function (_exports, _route, _userPayment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserBillingPaymentsRoute extends _route.default {
    templateName = "user/billing/payments";
    model() {
      return _userPayment.default.findAll();
    }
  }
  _exports.default = UserBillingPaymentsRoute;
});