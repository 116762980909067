define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-subscriptions", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-subscriptions/discourse/components/modal/admin-cancel-subscription", "discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription"], function (_exports, _controller, _object, _service, _discourseI18n, _adminCancelSubscription, _adminSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsSubscriptionsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    loading = false;
    showCancelModal(subscription) {
      this.modal.show(_adminCancelSubscription.default, {
        model: {
          subscription,
          cancelSubscription: this.cancelSubscription
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "showCancelModal", [_object.action]))();
    loadMore() {
      if (!this.loading && this.model.has_more) {
        this.set("loading", true);
        return _adminSubscription.default.loadMore(this.model.last_record).then(result => {
          const updated = this.model.data.concat(result.data);
          this.set("model", result);
          this.set("model.data", updated);
          this.set("loading", false);
        });
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    cancelSubscription(model) {
      const subscription = model.subscription;
      const refund = model.refund;
      const closeModal = model.closeModal;
      subscription.set("loading", true);
      subscription.destroy(refund).then(result => {
        subscription.set("status", result.status);
        this.dialog.alert(_discourseI18n.default.t("discourse_subscriptions.admin.canceled"));
      }).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n"))).finally(() => {
        subscription.set("loading", false);
        closeModal();
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "cancelSubscription", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsSubscriptionsController;
});