define("discourse/plugins/discourse-subscriptions/discourse/models/plan", ["exports", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Plan extends _object.default {
    get amountDollars() {
      return parseFloat(this.get("unit_amount") / 100).toFixed(2);
    }
    static #_ = (() => dt7948.n(this.prototype, "amountDollars", [(0, _object.computed)("unit_amount")]))();
    set amountDollars(value) {
      const decimal = parseFloat(value) * 100;
      this.set("unit_amount", decimal);
    }
    billingInterval(interval) {
      return interval || "one-time";
    }
    static #_2 = (() => dt7948.n(this.prototype, "billingInterval", [(0, _decorators.default)("recurring.interval")]))();
    subscriptionRate(amountDollars, currency, interval) {
      return `${amountDollars} ${currency.toUpperCase()} / ${interval}`;
    }
    static #_3 = (() => dt7948.n(this.prototype, "subscriptionRate", [(0, _decorators.default)("amountDollars", "currency", "billingInterval")]))();
  }
  _exports.default = Plan;
});