define("discourse/plugins/discourse-subscriptions/discourse/templates/connectors/user-main-nav/billing", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (user-viewing-self this.model)}}
    <LinkTo @route="user.billing">
      {{d-icon "far-credit-card"}}
      {{i18n "discourse_subscriptions.navigation.billing"}}
    </LinkTo>
  {{/if}}
  */
  {
    "id": "Iw+ClKR+",
    "block": "[[[41,[28,[37,1],[[30,0,[\"model\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@route\"],[\"user.billing\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"far-credit-card\"],null]],[1,\"\\n    \"],[1,[28,[35,4],[\"discourse_subscriptions.navigation.billing\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"user-viewing-self\",\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/connectors/user-main-nav/billing.hbs",
    "isStrictMode": false
  });
});