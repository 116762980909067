define("discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/lib/get-url", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _getUrl, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSubscription extends _object.default {
    static find() {
      return (0, _ajax.ajax)("/s/admin/subscriptions", {
        method: "get"
      }).then(result => {
        if (result === null) {
          return {
            unconfigured: true
          };
        }
        result.data = result.data.map(subscription => AdminSubscription.create(subscription));
        return result;
      });
    }
    static loadMore(lastRecord) {
      return (0, _ajax.ajax)(`/s/admin/subscriptions?last_record=${lastRecord}`, {
        method: "get"
      }).then(result => {
        result.data = result.data.map(subscription => AdminSubscription.create(subscription));
        return result;
      });
    }
    canceled(status) {
      return status === "canceled";
    }
    static #_ = (() => dt7948.n(this.prototype, "canceled", [(0, _decorators.default)("status")]))();
    metadataUserExists(metadata) {
      return metadata.user_id && metadata.username;
    }
    static #_2 = (() => dt7948.n(this.prototype, "metadataUserExists", [(0, _decorators.default)("metadata")]))();
    subscriptionUserPath(metadata) {
      return (0, _getUrl.default)(`/admin/users/${metadata.user_id}/${metadata.username}`);
    }
    static #_3 = (() => dt7948.n(this.prototype, "subscriptionUserPath", [(0, _decorators.default)("metadata")]))();
    destroy(refund) {
      const data = {
        refund
      };
      return (0, _ajax.ajax)(`/s/admin/subscriptions/${this.id}`, {
        method: "delete",
        data
      }).then(result => AdminSubscription.create(result));
    }
  }
  _exports.default = AdminSubscription;
});