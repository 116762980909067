define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-products-show", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _service, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsProductsShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    cancelProduct() {
      this.router.transitionTo("adminPlugins.discourse-subscriptions.products");
    }
    static #_2 = (() => dt7948.n(this.prototype, "cancelProduct", [_object.action]))();
    createProduct() {
      this.get("model.product").save().then(product => {
        this.router.transitionTo("adminPlugins.discourse-subscriptions.products.show", product.id);
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "createProduct", [_object.action]))();
    updateProduct() {
      this.get("model.product").update().then(() => {
        this.router.transitionTo("adminPlugins.discourse-subscriptions.products");
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateProduct", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsProductsShowController;
});