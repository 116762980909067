define("discourse/plugins/discourse-subscriptions/discourse/templates/user/billing/subscriptions/card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{i18n
      "discourse_subscriptions.user.subscriptions.update_card.heading"
      sub_id=this.model
    }}</h3>
  
  <div class="form-vertical">
    <div class="control-group">
      <SubscribeCard @cardElement={{this.cardElement}} class="input-xxlarge" />
    </div>
  
    <SaveControls
      @action={{action "updatePaymentMethod"}}
      @saved={{this.saved}}
      @saveDisabled={{this.loading}}
    />
  </div>
  */
  {
    "id": "UbntDbi1",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,1],[\"discourse_subscriptions.user.subscriptions.update_card.heading\"],[[\"sub_id\"],[[30,0,[\"model\"]]]]]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"form-vertical\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"input-xxlarge\"]],[[\"@cardElement\"],[[30,0,[\"cardElement\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,4],null,[[\"@action\",\"@saved\",\"@saveDisabled\"],[[28,[37,5],[[30,0],\"updatePaymentMethod\"],null],[30,0,[\"saved\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"h3\",\"i18n\",\"div\",\"subscribe-card\",\"save-controls\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/user/billing/subscriptions/card.hbs",
    "isStrictMode": false
  });
});