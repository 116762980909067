define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/template", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _service, _template, _ajax, _ajaxError, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    loading = false;
    stripeConfigured() {
      return !!this.siteSettings.discourse_subscriptions_public_key;
    }
    static #_2 = (() => dt7948.n(this.prototype, "stripeConfigured", [_decorators.default]))();
    campaignEnabled() {
      return this.siteSettings.discourse_subscriptions_campaign_enabled;
    }
    static #_3 = (() => dt7948.n(this.prototype, "campaignEnabled", [_decorators.default]))();
    campaignProductSet() {
      return !!this.siteSettings.discourse_subscriptions_campaign_product;
    }
    static #_4 = (() => dt7948.n(this.prototype, "campaignProductSet", [_decorators.default]))();
    triggerManualRefresh() {
      (0, _ajax.ajax)(`/s/admin/refresh`, {
        method: "post"
      }).then(() => {
        this.dialog.alert(_I18n.default.t("discourse_subscriptions.campaign.refresh_page"));
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "triggerManualRefresh", [_object.action]))();
    createOneClickCampaign() {
      this.dialog.yesNoConfirm({
        title: _I18n.default.t("discourse_subscriptions.campaign.confirm_creation_title"),
        message: (0, _template.htmlSafe)(_I18n.default.t("discourse_subscriptions.campaign.confirm_creation")),
        didConfirm: () => {
          this.set("loading", true);
          (0, _ajax.ajax)(`/s/admin/create-campaign`, {
            method: "post"
          }).then(() => {
            this.set("loading", false);
            this.dialog.confirm({
              message: _I18n.default.t("discourse_subscriptions.campaign.created"),
              shouldDisplayCancel: false,
              didConfirm: () => this.send("showSettings"),
              didCancel: () => this.send("showSettings")
            });
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "createOneClickCampaign", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsController;
});