define("discourse/plugins/discourse-subscriptions/discourse/models/product", ["exports", "@ember/object", "discourse/lib/ajax"], function (_exports, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Product extends _object.default {
    static findAll() {
      return (0, _ajax.ajax)("/s", {
        method: "get"
      }).then(result => result.map(product => Product.create(product)));
    }
  }
  _exports.default = Product;
});