define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-products-index", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "I18n", "discourse/plugins/discourse-subscriptions/discourse/models/admin-product"], function (_exports, _object, _route, _service, _I18n, _adminProduct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsProductsIndexRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    model() {
      return _adminProduct.default.findAll();
    }
    destroyProduct(product) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_subscriptions.admin.products.operations.destroy.confirm"),
        didConfirm: () => {
          return product.destroy().then(() => {
            this.controllerFor("adminPluginsDiscourseSubscriptionsProductsIndex").get("model").removeObject(product);
          }).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n")));
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "destroyProduct", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsProductsIndexRoute;
});