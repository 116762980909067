define("discourse/plugins/discourse-subscriptions/discourse/templates/subscriptions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="container">
    {{#if this.currentUser}}
      {{this.pricingTable}}
    {{else}}
      <LoginRequired />
    {{/if}}
  </div>
  */
  {
    "id": "aB33RUrE",
    "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n\"],[41,[30,0,[\"currentUser\"]],[[[1,\"    \"],[1,[30,0,[\"pricingTable\"]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]]],[13]],[],false,[\"div\",\"if\",\"login-required\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/subscriptions.hbs",
    "isStrictMode": false
  });
});