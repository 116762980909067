define("discourse/plugins/discourse-subscriptions/discourse/models/admin-product", ["exports", "@ember/object", "discourse/lib/ajax"], function (_exports, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminProduct extends _object.default {
    static findAll() {
      return (0, _ajax.ajax)("/s/admin/products", {
        method: "get"
      }).then(result => {
        if (result === null) {
          return {
            unconfigured: true
          };
        }
        return result.map(product => AdminProduct.create(product));
      });
    }
    static find(id) {
      return (0, _ajax.ajax)(`/s/admin/products/${id}`, {
        method: "get"
      }).then(product => AdminProduct.create(product));
    }
    isNew = false;
    metadata = {};
    destroy() {
      return (0, _ajax.ajax)(`/s/admin/products/${this.id}`, {
        method: "delete"
      });
    }
    save() {
      const data = {
        name: this.name,
        statement_descriptor: this.statement_descriptor,
        metadata: this.metadata,
        active: this.active
      };
      return (0, _ajax.ajax)("/s/admin/products", {
        method: "post",
        data
      }).then(product => AdminProduct.create(product));
    }
    update() {
      const data = {
        name: this.name,
        statement_descriptor: this.statement_descriptor,
        metadata: this.metadata,
        active: this.active
      };
      return (0, _ajax.ajax)(`/s/admin/products/${this.id}`, {
        method: "patch",
        data
      });
    }
  }
  _exports.default = AdminProduct;
});