define("discourse/plugins/discourse-subscriptions/discourse/components/subscribe-card", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="card-element"></div>
  */
  {
    "id": "haJ3ea3e",
    "block": "[[[10,0],[14,1,\"card-element\"],[12],[13]],[],false,[\"div\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/subscribe-card.hbs",
    "isStrictMode": false
  });
  class SubscribeCard extends _component.default {
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.cardElement.mount("#card-element");
      this.setCardElementStyles();
    }
    setCardElementStyles() {
      const root = document.querySelector(":root");
      const computedStyle = getComputedStyle(root);
      const primaryColor = computedStyle.getPropertyValue("--primary");
      const placeholderColor = computedStyle.getPropertyValue("--secondary-medium");
      this.cardElement.update({
        style: {
          base: {
            color: primaryColor,
            "::placeholder": {
              color: placeholderColor
            }
          }
        }
      });
    }
    didDestroyElement() {
      super.didDestroyElement(...arguments);
    }
  }
  _exports.default = SubscribeCard;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SubscribeCard);
});