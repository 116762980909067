define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-coupons", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax-error", "discourse/plugins/discourse-subscriptions/discourse/models/admin-coupon"], function (_exports, _controller, _object, _ajaxError, _adminCoupon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsCouponsController extends _controller.default {
    creating = null;
    openCreateForm() {
      this.set("creating", true);
    }
    static #_ = (() => dt7948.n(this.prototype, "openCreateForm", [_object.action]))();
    closeCreateForm() {
      this.set("creating", false);
    }
    static #_2 = (() => dt7948.n(this.prototype, "closeCreateForm", [_object.action]))();
    createNewCoupon(params) {
      _adminCoupon.default.save(params).then(() => {
        this.send("closeCreateForm");
        this.send("reloadModel");
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "createNewCoupon", [_object.action]))();
    deleteCoupon(coupon) {
      _adminCoupon.default.destroy(coupon).then(() => {
        this.send("reloadModel");
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_4 = (() => dt7948.n(this.prototype, "deleteCoupon", [_object.action]))();
    toggleActive(coupon) {
      const couponData = {
        id: coupon.id,
        active: !coupon.active
      };
      _adminCoupon.default.update(couponData).then(() => {
        this.send("reloadModel");
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_5 = (() => dt7948.n(this.prototype, "toggleActive", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsCouponsController;
});