define("discourse/plugins/discourse-subscriptions/discourse/templates/subscribe/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.isLoggedIn}}
    <LoginRequired />
  {{/unless}}
  
  <ProductList @products={{this.model}} @isLoggedIn={{this.isLoggedIn}} />
  */
  {
    "id": "UIFC2Wbm",
    "block": "[[[41,[51,[30,0,[\"isLoggedIn\"]]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,2],null,[[\"@products\",\"@isLoggedIn\"],[[30,0,[\"model\"]],[30,0,[\"isLoggedIn\"]]]],null]],[],false,[\"unless\",\"login-required\",\"product-list\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/subscribe/index.hbs",
    "isStrictMode": false
  });
});