define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-dashboard", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseSubscriptionsDashboardController extends _controller.default {
    queryParams = ["order", "descending"];
    order = null;
    descending = true;
    loadMore() {}
    static #_ = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    orderPayments(order) {
      if (order === this.get("order")) {
        this.toggleProperty("descending");
      }
      this.set("order", order);
    }
    static #_2 = (() => dt7948.n(this.prototype, "orderPayments", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsDashboardController;
});