define("discourse/plugins/discourse-subscriptions/discourse/templates/user/billing/payments", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model}}
    <table class="table discourse-subscriptions-user-table">
      <thead>
        <th>{{i18n "discourse_subscriptions.user.payments.id"}}</th>
        <th>{{i18n "discourse_subscriptions.user.payments.amount"}}</th>
        <th>{{i18n "discourse_subscriptions.user.payments.created_at"}}</th>
      </thead>
      <tbody>
        {{#each this.model as |payment|}}
          <tr>
            <td>{{payment.id}}</td>
            <td>{{format-currency payment.currency payment.amountDollars}}</td>
            <td>{{format-unix-date payment.created}}</td>
          </tr>
        {{/each}}
      </tbody>
    </table>
  {{else}}
    <div class="alert alert-info">
      {{i18n "discourse_subscriptions.user.payments_help"}}
    </div>
  {{/if}}
  */
  {
    "id": "p1dwUoLo",
    "block": "[[[41,[30,0,[\"model\"]],[[[1,\"  \"],[10,\"table\"],[14,0,\"table discourse-subscriptions-user-table\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,[28,[35,4],[\"discourse_subscriptions.user.payments.id\"],null]],[13],[1,\"\\n      \"],[10,\"th\"],[12],[1,[28,[35,4],[\"discourse_subscriptions.user.payments.amount\"],null]],[13],[1,\"\\n      \"],[10,\"th\"],[12],[1,[28,[35,4],[\"discourse_subscriptions.user.payments.created_at\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,1,[\"id\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[28,[35,10],[[30,1,[\"currency\"]],[30,1,[\"amountDollars\"]]],null]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[28,[35,11],[[30,1,[\"created\"]]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[\"discourse_subscriptions.user.payments_help\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"payment\"],false,[\"if\",\"table\",\"thead\",\"th\",\"i18n\",\"tbody\",\"each\",\"-track-array\",\"tr\",\"td\",\"format-currency\",\"format-unix-date\",\"div\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/user/billing/payments.hbs",
    "isStrictMode": false
  });
});