define("discourse/plugins/discourse-subscriptions/discourse/components/product-list", ["exports", "@ember/component", "@ember/utils", "@ember-decorators/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _utils, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.emptyProducts}}
    <p>{{i18n "discourse_subscriptions.subscribe.no_products"}}</p>
  {{else}}
    {{#each this.products as |product|}}
      <ProductItem @product={{product}} @isLoggedIn={{this.isLoggedIn}} />
    {{/each}}
  {{/if}}
  */
  {
    "id": "ub0neBPH",
    "block": "[[[41,[30,0,[\"emptyProducts\"]],[[[1,\"  \"],[10,2],[12],[1,[28,[35,2],[\"discourse_subscriptions.subscribe.no_products\"],null]],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"products\"]]],null]],null],null,[[[1,\"    \"],[8,[39,5],null,[[\"@product\",\"@isLoggedIn\"],[[30,1],[30,0,[\"isLoggedIn\"]]]],null],[1,\"\\n\"]],[1]],null]],[]]]],[\"product\"],false,[\"if\",\"p\",\"i18n\",\"each\",\"-track-array\",\"product-item\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/product-list.hbs",
    "isStrictMode": false
  });
  const ProductList = dt7948.c(class ProductList extends _component.default {
    emptyProducts(products) {
      return (0, _utils.isEmpty)(products);
    }
    static #_ = (() => dt7948.n(this.prototype, "emptyProducts", [(0, _decorators.default)("products")]))();
  }, [(0, _component2.classNames)("product-list")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ProductList);
});